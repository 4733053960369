
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    $header-height-small: 48px;
    $header-height-large: 64px;
    $vertical-offset: $nu-spacer-4 + $header-height-small;
    $vertical-offset-large: $nu-spacer-4 + $header-height-large;
    $vh70: calc(70 * var(--vh));

    .c-common-homepage-video {
        width: 100%;
        height: 260px;
        margin-bottom: $nu-spacer-1;
        position: relative;
        overflow: hidden;
        border-radius: 4px;

        @include breakpoint(medium) {
            min-height: 260px;
            // not using --vh here because layout doesnt need to perfectly fit on screen
            // using --vh was causing jumpyness on iOS devices
            height: calc(#{$vh70} - #{$vertical-offset});
        }

        @include breakpoint(large) {
            width: 65%;
            min-height: 336px;
            height: calc(var(--vh100) - #{$vertical-offset-large});
            margin-bottom: 0;
        }

        &__player {
            object-fit: cover;
            object-position: center;
            min-height: 100%;
            min-width: 100%;
            height: 100%;
            width: 100%;
        }

        &__overlay {
            background-image:
                linear-gradient(to bottom, $transparent 0%, $transparent 40%, $nu-black 100%);
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            opacity: .6;
        }

        &__button {
            position: absolute;
            top: $nu-spacer-2;
            right: $nu-spacer-2;
        }

        &__heading-wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
        }

        &__heading {
            padding: $nu-spacer-2pt5;
            color: $nu-white;
            @include heading-1;

            @include breakpoint(medium) {
                padding: $nu-spacer-6;
                @include title-1;
            }

            @include breakpoint(large) {
                padding-right: calc($nu-spacer-16 + $nu-spacer-4);
            }
        }
    }
