
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-common-homepage-brand-story {
        width: 100%;

        &__content-wrap {
            padding: $nu-spacer-20 0;

            @include breakpoint(medium) {
                padding: 256px 0;
            }
        }

        &__content {
            @include heading-2;

            @include breakpoint(large) {
                @include title-4;
                line-height: 48px;
            }
        }

        &__buttons {
            display: flex;
            justify-content: center;
            width: 100%;

            button {
                width: 167px;

                @include breakpoint(large) {
                    width: 200px;
                }
            }

            button:first-child {
                margin-right: $nu-spacer-1;

                @include breakpoint(medium) {
                    margin-right: $nu-spacer-2;
                }
            }
        }
    }
