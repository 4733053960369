
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    $header-height-small: 48px;
    $header-height-large: 64px;
    $vertical-offset: $nu-spacer-4 + $header-height-small;
    $vertical-offset-large: $nu-spacer-4 + $header-height-large;
    $vh30: calc(30 * var(--vh));

    .c-common-homepage-callouts {
        $this: &;

        width: 100%;
        min-height: 168px;
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(medium) {
            flex-wrap: nowrap;
            height: calc(#{$vh30} - #{$nu-spacer-2});
        }

        @include breakpoint(large) {
            width: 35%;
            height: calc(var(--vh100) - #{$vertical-offset-large});
            flex-wrap: wrap;
            padding-left: $nu-spacer-2;
        }

        &__box {
            width: 100%;
            height: auto;
            min-height: 168px;

            @include breakpoint(medium) {
                width: 50%;
                height: calc(#{$vh30} - #{$nu-spacer-2});
            }

            @include breakpoint(large) {
                width: 100%;
                height: 50%;
            }

            &--rental {
                margin-bottom: $nu-spacer-1;

                @include breakpoint(medium) {
                    margin-right: $nu-spacer-1;
                    margin-bottom: 0;
                }

                @include breakpoint(large) {
                    margin-right: 0;
                    padding-bottom: $nu-spacer-2;
                }
            }
        }

        &__box-content {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            text-align: center;
            position: relative;
            flex-direction: column;
            background-size: cover;
            cursor: pointer;
        }

        &__box-content-inner {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            flex-direction: column;
            transition: all .5s ease;
            padding: $nu-spacer-5 $nu-spacer-2;

            @include breakpoint(medium) {
                padding: 0 $nu-spacer-2;
            }

            @include breakpoint(large) {
                padding: 0 $nu-spacer-3;
            }

            @include breakpoint(extra-large) {
                padding: 0 $nu-spacer-6;
            }

            &--rental {
                background-color: $nu-secondary--100;
                color: $nu-primary;

                @media(hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: rgba($nu-secondary--100, .7);
                    }
                }
            }

            &--thrift {
                background-color: $nu-primary;
                color: $nu-secondary--100;

                @media(hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: rgba($nu-primary, .7);
                    }
                }
            }
        }

        &__logo {
            width: 122px;
            height: 24px;
            fill: $nu-primary;
            color:  $nu-primary;
            margin-bottom: $nu-spacer-1;

            @include breakpoint(medium) {
                width: 167px;
                height: 32px;
            }

            svg {
                width: 100%;
                max-width: 100%;
            }

            &--thrift {
                fill: $nu-secondary--100;
                color: $nu-secondary--100;
            }
        }

        &__link {
            &--thrift {
                color: $nu-secondary--100;
            }
        }

        &__link-arrow {
            max-width: 0px;
            padding-top: $nu-spacer-0pt5;
            padding-left: $nu-spacer-0pt5;

            @media(hover: hover) and (pointer: fine) {
                #{$this}__box-content-inner:hover & {
                    max-width: 16px;
                }
            }
        }
    }
