
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-text-and-illustration {
        &__illustration {
           vertical-align: middle;
           line-height: 1;
        }
    }
