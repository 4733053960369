$sans: sans-serif;
$font-weight-normal:    400;
$font-weight-medium:    500;
$font-weight-semibold:  600;
$font-weight-bold:      700;

$typeface-ginto: 'Ginto Nord', $sans;
$ginto-directory: '/static/lib/fonts/ginto-nord/GintoNord-';

$typeface-sharp: 'Sharp Grotesk 20', $sans;
$sharp-directory: '/static/lib/fonts/sharp-grotesk/SharpGrotesk20-';

$typeface-americana: 'Americana', $sans;
$americana-directory: '/static/lib/fonts/americana/Americana-';
