
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-common-anonymous-homepage {
    padding: $nu-spacer-1;
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(large) {
        padding: $nu-spacer-2;
    }
}
