
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-video-button {
        $this: &;

        display: flex;
        height: 32px;
        width: auto;
        padding: $nu-spacer-1;
        justify-content: flex-start;
        align-items: center;
        background-color: $nu-white;
        border-radius: 8px;
        user-select: none;
        overflow: hidden;
        cursor: pointer;

        &__text {
            overflow: hidden;
            max-width: 0;
            transition: all .2s ease;
            display: inline-block;
            white-space: nowrap;
            opacity: 0;

            &--faceout {
                max-width: 100px;
                padding-left: $nu-spacer-1;
                opacity: 1;
            }

            @media(hover: hover) and (pointer: fine) {
                #{$this}:hover & {
                    max-width: 100px;
                    padding-left: $nu-spacer-1;
                    opacity: 1;
                }
            }
        }
    }
